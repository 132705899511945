/* global google */
import { useEffect, useState } from 'react'
import { useGooglePlacesAPI } from '@dfds-ui/google-places'

const initialState = {
  countryShortName: null,
  countryLongName: null,
  error: null,
  loaded: false,
}

export const useFindCountryFromLocation = (
  acceptParams,
  latitude,
  longitude,
) => {
  const [countryInfo, setCountryInfo] = useState(initialState)
  const { available } = useGooglePlacesAPI()

  useEffect(() => {
    if (acceptParams === 'false' && (!latitude || !longitude)) return
    if (acceptParams !== 'false') {
      const acceptParamsSplit = acceptParams.split(',')

      setCountryInfo({
        ...initialState,
        countryShortName: acceptParamsSplit[0].split('-').pop() || '',
        countryLongName: acceptParamsSplit[1] || '',
        loaded: true,
      })

      return
    }

    if (countryInfo.loaded) return countryInfo
    if (!available || !latitude || !longitude) return { ...initialState }

    const geoCoder = new google.maps.Geocoder()
    const latlng = { lat: latitude, lng: longitude }

    geoCoder.geocode({ location: latlng }, (result, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addressComponentContainsCountryInfo =
          result[0].address_components.find((address) =>
            address.types.includes('country'),
          )
        setCountryInfo({
          ...initialState,
          countryShortName:
            addressComponentContainsCountryInfo.short_name || '',
          countryLongName: addressComponentContainsCountryInfo.long_name || '',
          loaded: true,
        })

        return
      }

      setCountryInfo({
        ...initialState,
        error: status,
        loaded: true,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptParams, latitude, longitude])

  return countryInfo
}
