import { css } from '@emotion/react'
import {
  Banner,
  BannerAction,
  BannerParagraph,
  LocaleFlag,
} from '@dfds-ui/react-components'
import { useTranslation } from 'react-i18next'
import { useFindBusinessUnitLandingPageForSpecificLocale } from '../SiteSettings'
import { usePageContext } from '../PageContext'
import { track } from '@dfds-frontend/tracking'
import { useEffect } from 'react'

const LocaleSelectionBanner = ({
  locale,
  countryName,
  onSelectLocaleCancelledByUser,
  onSelectLocaleAcceptedByUser,
}) => {
  const { t } = useTranslation()
  const businessUnitLandingPageForDetectedLocale =
    useFindBusinessUnitLandingPageForSpecificLocale(locale)
  const { hrefLangs = {}, slug } = usePageContext()
  const localizedLink =
    slug && slug !== '/' && hrefLangs[locale]
      ? `/${locale.toLowerCase()}/${hrefLangs[locale]}`
      : `/${locale.toLowerCase()}/${businessUnitLandingPageForDetectedLocale}`

  useEffect(() => {
    track({
      event: 'displayBanner',
      market: 'INT',
      role: 'agreement',
      impersonation: false,
      isPrimaryAssociate: false,
      countryCode: 'INT',
      bookingEngine: 'dotcom',
      businessArea: 'passenger',
      flow: 'LocaleSelectionBanner',
    })
  }, [])

  return (
    <Banner
      divider
      intent="info"
      onRequestClose={() => {
        track({
          event: 'buttonClicked',
          eventAction: 'closeLocaleSelector',
          bookingEngine: 'dotcom',
          businessArea: 'passenger',
          countryCode: 'INT',
          flow: 'LocaleSelectionBanner',
        })
        onSelectLocaleCancelledByUser()
      }}
      actions={[
        <BannerAction
          key="primary-action"
          variation="primary"
          onClick={() => {
            track({
              event: 'buttonClicked',
              eventAction: 'applyLocaleSelector',
              bookingEngine: 'dotcom',
              businessArea: 'passenger',
              countryCode: 'INT',
              flow: 'LocaleSelectionBanner',
            })
            onSelectLocaleAcceptedByUser(localizedLink)
          }}
        >
          {t('IN-LOCALE-SELECTION-BANNER-FOR-APPLY-LOCATION')}
        </BannerAction>,
      ]}
      variant="lowEmphasis"
    >
      <BannerParagraph>
        {t('IN-LOCALE-SELECTION-BANNER-FOR-WE-DETECTED-YOUR-LOCALE')}{' '}
        {countryName}
        <LocaleFlag
          locale={locale}
          css={css`
            margin-left: 1rem;
          `}
        />
      </BannerParagraph>
    </Banner>
  )
}

export default LocaleSelectionBanner
