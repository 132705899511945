import { useEffect, useState } from 'react'

import {
  BusinessUnitTypes,
  useBusinessUnitContext,
} from '../BusinessUnitContext'
import { useLocaleContext } from '../LocaleContext'
import { useSiteSettingsContext } from '../SiteSettings'
import LocaleSelectionBanner from './LocaleSelectionBanner'
import { useBrowserCoordinates } from './useBrowserCoordinates'
import { useFindCountryFromLocation } from './useFindCountryFromLocation'

const GeoLocationDetector = () => {
  const [handleClose, setHandleClose] = useState(
    typeof localStorage !== 'undefined' && localStorage.localeBannerNotVisible
      ? localStorage.localeBannerNotVisible
      : 'false',
  )
  const [handleAccept, setHandleAccept] = useState(
    typeof localStorage !== 'undefined' &&
      localStorage.localeBannerAcceptedLocale
      ? localStorage.localeBannerAcceptedLocale
      : 'false',
  )

  const { availableLanguagesCollection } = useSiteSettingsContext()
  const browserCoordinatesResult = useBrowserCoordinates()
  const countryInfo = useFindCountryFromLocation(
    handleAccept,
    browserCoordinatesResult?.latitude,
    browserCoordinatesResult?.longitude,
  )

  const findLocaleFromCountryName = (countryShortName, availableLanguages) => {
    if (availableLanguages?.length && countryShortName) {
      const localeInfoForUser = availableLanguages.filter((l) =>
        l.locale.endsWith(countryShortName),
      )

      return localeInfoForUser?.length ? localeInfoForUser[0] : null
    }

    return null
  }

  const detectedLocale = findLocaleFromCountryName(
    countryInfo?.countryShortName,
    availableLanguagesCollection?.items,
  )
  const { locale: currentLocale } = useLocaleContext()
  const currentBusinessUnit = useBusinessUnitContext()

  const showLocaleBanner =
    // Do not show if about pages, they are only available on English locale
    currentBusinessUnit !== BusinessUnitTypes.GROUP &&
    // Do not show if the user has closed the location banner for this session
    handleClose !== 'true' &&
    // Do not show if user declines the location tracking in browser or the browserCoordinatesResult is loading
    (browserCoordinatesResult?.loading ||
      !browserCoordinatesResult.isGeoLocationBlocked) &&
    // Do not show if detected locale is the same as current locale
    detectedLocale?.locale &&
    detectedLocale.locale !== currentLocale

  useEffect(() => {
    localStorage.localeBannerNotVisible = handleClose
  }, [handleClose])

  useEffect(() => {
    localStorage.localeBannerAcceptedLocale = handleAccept
  }, [handleAccept])

  return (
    !!showLocaleBanner && (
      <LocaleSelectionBanner
        locale={detectedLocale.locale}
        countryName={countryInfo?.countryLongName}
        onSelectLocaleCancelledByUser={() => {
          setHandleClose('true')
        }}
        onSelectLocaleAcceptedByUser={(localizedLink) => {
          setHandleAccept([detectedLocale.locale, countryInfo?.countryLongName])
          window.location.href = localizedLink
        }}
      />
    )
  )
}

export default GeoLocationDetector
