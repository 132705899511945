import { useEffect, useState } from 'react'

const initialStatus = {
  status: 'initial',
  latitude: null,
  longitude: null,
  error: null,
  isGeoLocationBlocked: false,
  loading: true,
}

export const useBrowserCoordinates = () => {
  const [locationInfo, setLocationInfo] = useState(initialStatus)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setLocationInfo({
            ...initialStatus,
            status: 'success',
            latitude,
            longitude,
            loading: false,
          })
        },
        (error) => {
          setLocationInfo({
            ...initialStatus,
            status: 'error',
            error,
            isGeoLocationBlocked: true,
            loading: false,
          })
        },
      )
    } else {
      setLocationInfo({
        ...initialStatus,
        status: 'error',
        error: 'Browser does not support location',
        loading: false,
      })
    }
  }, [])

  return locationInfo
}
